<div [ngClass]="{ 'd-none': !subscriptionStatusBarVisible() }" class="d-flex align-items-center ms-1 ms-lg-3">
    
    <!--<span id="notificationIcon" class="me-3" *ngIf="!isMobile">
        <a class="cursor-pointer" (click)="extendTenantTrialDate()">{{"PleaseExtendMyFreeTrial"|localize}}</a>
    </span>
    <span id="notificationIcon" class="me-3">
        <div>
            <a routerLink="/app/admin/subscription-management" (click)="navigateToPaymentScreen()" class="button w-100">{{'Upgrade'|localize}}</a>-->
            <!--<button class="btn btn-sm w-100 btn-warning" (click)="navigateToPaymentScreen()" routerLink="/app/admin/subscription-management">
                <strong>{{'Upgrade'|localize}}</strong>
            </button>-->
        <!--</div>
        <div class="fontSize">
            Your trial will end in
            <countdown *ngIf="getTenantExpiresCountDown() >=86400" #cd [config]="{ leftTime: getTenantExpiresCountDown(), format: 'd' }" />
            <span *ngIf="getTenantExpiresCountDown() < 86400">0</span> days,
            <countdown #cd [config]="{ leftTime: getTenantExpiresCountDown(), format: 'HH' }" /> hrs,
            <countdown #cd [config]="{ leftTime: getTenantExpiresCountDown(), format: 'm' }" /> min.
        </div>
    </span>-->
    <!--<div [class]="customStyle" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
        <i class="flaticon-alert fs-2 glowing"></i>
        <span class="
                bullet bullet-dot
                bg-success
                h-6px
                w-6px
                position-absolute
                translate-middle
                top-0
                start-50
                animation-blink
            "></span>
    </div>
    <div class="menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-400px p-5" data-kt-menu="true">
        <span *ngIf="appSession.tenant?.isInTrialPeriod && !subscriptionIsExpiringSoon()"
            [innerHTML]="getTrialSubscriptionNotification()"></span>
        <a *ngIf="subscriptionIsExpiringSoon()" routerLink="/app/admin/subscription-management">
            <span *ngIf="subscriptionIsExpiringSoon() && appSession.tenant.isInTrialPeriod">
                <i class="fa fa-exclamation-triangle"></i>
                {{ getExpireNotification('TrialExpireNotification') }}
            </span>
            <span *ngIf="subscriptionIsExpiringSoon() && !appSession.tenant.isInTrialPeriod">
                <i class="fa fa-exclamation-triangle"></i>
                {{ getExpireNotification('SubscriptionExpireNotification') }}
            </span>
        </a>
    </div>-->
</div>