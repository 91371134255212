import { Component } from '@angular/core';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { AppConsts } from '@shared/AppConsts';
import { AppSessionService } from '@shared/common/session/app-session.service';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
        <ngx-spinner type="ball-clip-rotate" size="medium" [color]="themeColor">
            <p *ngIf="ngxSpinnerText">{{ getSpinnerText() }}</p>
        </ngx-spinner>
    `,
})
export class RootComponent {
    ngxSpinnerText: NgxSpinnerTextService;
    themeColor: string;

    constructor(_ngxSpinnerText: NgxSpinnerTextService) {
        this.ngxSpinnerText = _ngxSpinnerText;
        var color = abp.setting.get(AppConsts.themeColorSetting);
        this.themeColor = !color ? '#0b4171' : color;
        
    }

    getSpinnerText(): string {
        return this.ngxSpinnerText.getText();
    }
}
