<a href="/">
    <img
        *ngIf="!appSession.tenant || !appSession.tenant.HasLogo()"
        src="/assets/common/images/app-logo-on-dark.svg"
        alt="logo"
        class="h-50px h-lg-50px"
    />
    <img
        *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
        [src]="
            remoteServiceBaseUrl +
            '/TenantCustomization/GetTenantLogo?skin=dark' +
            '&tenantId=' +
            appSession.tenant.id+ '&logoId='+appSession.tenant.LogoId('dark')
        "
        alt="logo"
        class="h-50px h-lg-50px"
    />
</a>
