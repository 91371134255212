<a href="/">
    <img *ngIf="!appSession.tenant || !appSession.tenant.HasLogo()" [src]="defaultLogo" alt="logo"  class="h-50px logo" />
    <img
        *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
        [src]="
            remoteServiceBaseUrl +
            '/TenantCustomization/GetTenantLogo?skin=' +
            skin +
            '&tenantId=' +
            appSession.tenant.id+ '&logoId='+appSession.tenant.LogoId(this.skin)
        "
        class="h-50px logo"
    />
</a>