import { FeatureCheckerService, PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _featureCheckerService: FeatureCheckerService,
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem(
                'Dashboard',
                'Pages.Administration.Host.Dashboard',
                'flaticon-line-graph',
                '/app/admin/hostDashboard'
            ),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Participants', "Pages.Participants", 'fas fa-people-group', '/app/main/participants/participants', undefined, undefined, undefined,undefined,() => {
                return this._featureCheckerService.isEnabled('App.Participants');
            }),
            new AppMenuItem('ManageGroups', 'Pages.ManageGroups', 'fa fa-users', '/app/main/manageGroups/manageGroups', undefined, undefined, undefined,undefined,() => {
                return this._featureCheckerService.isEnabled('App.Groups');
            }),
            new AppMenuItem('Resources', 'Pages.Resources', 'fa fa-bolt', '/app/main/resources/resources'),
            new AppMenuItem('Resources', 'Pages.Tenant.Resources', 'fa fa-bolt', '/app/main/tenant-resources/tenant-resources', undefined, undefined, undefined,undefined,() => {
                return this._featureCheckerService.isEnabled('App.Resources');
            }),
            
            
            new AppMenuItem('EmbedSettings', 'Pages.EmbedSettings', 'fa fa-code-fork', '/app/main/embedSettings/embedSettings'),
            new AppMenuItem('Campaigns', 'Pages.Tenant.Campaigns', 'fa fa-bullhorn', '/app/main/campaigns/campaigns', undefined, undefined, undefined,undefined,() => {
                return this._featureCheckerService.isEnabled('App.Campaigns');
            }),
            new AppMenuItem('Campaigns', 'Pages.Campaigns', 'fa fa-bullhorn', '/app/main/campaigns/campaigns'),
            
            new AppMenuItem('ResultConfigurations', 'Pages.AssessmentResults', 'fa fa-crosshairs', '/app/main/resultConfigurations/resultConfigurations'),
            
            new AppMenuItem('AssessmentFieldsConfigurations', 'Pages.AssessmentFieldsConfigurations', 'fa fa-cogs', '/app/main/assessmentFieldsConfigurations/assessmentFieldsConfigurations'),
             new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('AccountSettings','Pages.Account.Settings','flaticon-settings','/app/main/accountSettings/accountSettings', undefined, undefined, undefined,undefined,() => {
                return this._featureCheckerService.isEnabled('App.AccountSettings');
            }),
            new AppMenuItem(
                'Administration',
                '',
                'flaticon-interface-8',
                '',
                [],
                [
                    new AppMenuItem('EmailQueues', 'Pages.View.Queued.Emails', 'fa fa-pause', '/app/main/queuedEmails/queuedEmails'),
                    new AppMenuItem('EmailQueues', 'Pages.Tenant.View.Queued.Emails', 'fa fa-pause', '/app/main/queuedEmails/queuedEmails', undefined, undefined, undefined,undefined,() => {
                        return this._featureCheckerService.isEnabled('App.QueuedEmails');
                    }),
                        new AppMenuItem('EmailTemplates', 'Pages.EmailTemplates', 'fa fa-file-text', '/app/main/emailTemplate/emailTemplate'),
                        new AppMenuItem('EmailTemplates', 'Pages.Tenant.EmailTemplates', 'fa fa-file-text', '/app/main/emailTemplate/emailTemplate', undefined, undefined, undefined,undefined,() => {
                            return this._featureCheckerService.isEnabled('App.EmailTemplates');
                        }),
                    new AppMenuItem('EmailSignature', 'Pages.EmailSignature', 'fa fa-signature', '/app/main/host-email-signature'),
                    new AppMenuItem('EmailSignature', 'Pages.Tenant.EmailSignature', 'fa fa-signature', '/app/main/email-signature', undefined, undefined, undefined,undefined,() => {
                        return this._featureCheckerService.isEnabled('App.EmailSignature');
                    }),
                    new AppMenuItem(
                        'OrganizationUnits',
                        'Pages.Tenant.Administration.OrganizationUnits',
                        'flaticon-map',
                        '/app/admin/organization-units', undefined, undefined, undefined,undefined,() => {
                            return this._featureCheckerService.isEnabled('App.OrganisationUnits');
                        }),
                    new AppMenuItem('Roles', 'Pages.Tenant.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles', undefined, undefined, undefined,undefined,() => {
                        return this._featureCheckerService.isEnabled('App.Roles');
                    },false),
                    new AppMenuItem(
                        'OrganizationUnits',
                        'Pages.Administration.OrganizationUnits',
                        'flaticon-map',
                        '/app/admin/organization-units'),
                    new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                    new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                    new AppMenuItem('Users', 'Pages.Tenant.Administration.Users', 'flaticon-users', '/app/admin/users', undefined, undefined, undefined,undefined,() => {
                        return this._featureCheckerService.isEnabled('App.Users');
                    },false),
                    new AppMenuItem(
                        'Languages',
                        'Pages.Administration.Languages',
                        'flaticon-tabs',
                        '/app/admin/languages',
                        ['/app/admin/languages/{name}/texts']),
                    new AppMenuItem(
                        'Languages',
                        'Pages.Tenant.Administration.Languages',
                        'flaticon-tabs',
                        '/app/admin/languages',
                        ['/app/admin/languages/{name}/texts'], undefined, undefined,undefined,() => {
                            return this._featureCheckerService.isEnabled('App.Languages');
                        }),
                    new AppMenuItem(
                        'AuditLogs',
                        'Pages.Administration.AuditLogs',
                        'flaticon-folder-1',
                        '/app/admin/auditLogs'),

                    new AppMenuItem(
                        'AuditLogs',
                        'Pages.Tenant.Administration.AuditLogs',
                        'flaticon-folder-1',
                        '/app/admin/auditLogs', undefined, undefined, undefined,undefined,() => {
                            return this._featureCheckerService.isEnabled('App.AuditLogs');
                        }),
                    new AppMenuItem(
                        'Maintenance',
                        'Pages.Administration.Host.Maintenance',
                        'flaticon-lock',
                        '/app/admin/maintenance'
                    ),
                    new AppMenuItem(
                        'Subscription',
                        'Pages.Administration.Tenant.SubscriptionManagement',
                        'flaticon-refresh',
                        '/app/admin/subscription-management', undefined, undefined, undefined,undefined,() => {
                            return this._featureCheckerService.isEnabled('App.Subscription');
                        }),
                    new AppMenuItem(
                        'VisualSettings',
                        'Pages.Administration.UiCustomization',
                        'flaticon-medical',
                        '/app/admin/ui-customization'
                    ),
                    new AppMenuItem(
                        'WebhookSubscriptions',
                        'Pages.Administration.WebhookSubscription',
                        'flaticon2-world',
                        '/app/admin/webhook-subscriptions'
                    ),
                    new AppMenuItem(
                        'DynamicProperties',
                        'Pages.Administration.DynamicProperties',
                        'flaticon-interface-8',
                        '/app/admin/dynamic-property'
                    ),
                    new AppMenuItem(
                        'Notifications',
                        '',
                        'flaticon-alarm',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'Inbox',
                                '',
                                'flaticon-mail-1',
                                '/app/notifications'
                            ),
                            new AppMenuItem(
                                'MassNotifications',
                                'Pages.Administration.MassNotification',
                                'flaticon-paper-plane',
                                '/app/admin/mass-notifications'),
                            new AppMenuItem(
                                'MassNotifications',
                                'Pages.Tenant.Administration.MassNotification',
                                'flaticon-paper-plane',
                                '/app/admin/mass-notifications', undefined, undefined, undefined,undefined,() => {
                                    return this._featureCheckerService.isEnabled('App.MassNotification');
                                }),
                        ]
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Host.Settings',
                        'flaticon-settings',
                        '/app/admin/hostSettings'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Tenant.Settings',
                        'flaticon-settings',
                        '/app/admin/tenantSettings', undefined, undefined, undefined,undefined,() => {
                            return this._featureCheckerService.isEnabled('App.Settings');
                        })
                ]
            ),
            //new AppMenuItem(
            //    'DemoUiComponents',
            //    'Pages.DemoUiComponents',
            //    'flaticon-shapes',
            //    '/app/admin/demo-ui-components'
            //),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                if (!subMenuItem.hasFeatureDependency()) {
                    return true;
                }

                if (subMenuItem.featureDependencySatisfied()) {
                    return true;
                }
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
