import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GtmService {
    public pushEvent(eventName: string, eventData: any = {}): void {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
            event: eventName,
            ...eventData
        });
    }

    public loadGTM(key: string) {
        const gtmKey = key;
        const scriptTag = document.createElement('script');
        scriptTag.innerHTML =
            `(
           function(w, d, s, l, i) { 
           w[l] = w[l] || []; w[l].push({
           'gtm.start': new Date().getTime(), event: 'gtm.js'
        }); 
        var f = d.getElementsByTagName(s)[0], 
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; 
        j.async = true; 
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; 
        f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${gtmKey}');`;

        const headElement = document.head || document.getElementsByTagName('head')[0];
        headElement.insertBefore(scriptTag, headElement.firstChild);

        const noscriptTag = document.createElement('noscript');
        noscriptTag.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmKey}" 
              height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

        const bodyElement = document.body || document.getElementsByTagName('body')[0];
        bodyElement.insertBefore(noscriptTag, bodyElement.firstChild);
    }

}
