<a href="/">
    <span *ngIf="isMobile && !subscriptionStatusBarVisible()">
        <img
        *ngIf="!appSession.tenant || !appSession.tenant.HasLogo()"
        src="/assets/common/images/app-logo-on-dark.svg"
        alt="logo"
        class="h-20px"
    />
    <img
        *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
        [src]="
            remoteServiceBaseUrl +
            '/TenantCustomization/GetTenantLogo?skin=dark' +
            '&tenantId=' +
            appSession.tenant.id + '&logoId='+appSession.tenant.LogoId('dark')
        "
        alt="logo"
        class="h-20px"
    />
    </span>
    <span *ngIf="!isMobile">
        <img
        *ngIf="!appSession.tenant || !appSession.tenant.HasLogo()"
        src="/assets/common/images/app-logo-on-dark.svg"
        alt="logo"
        class="h-55px h-lg-80px"
    />
    <img
        *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
        [src]="
            remoteServiceBaseUrl +
            '/TenantCustomization/GetTenantLogo?skin=dark' +
            '&tenantId=' +
            appSession.tenant.id + '&logoId='+appSession.tenant.LogoId('dark')
        "
        alt="logo"
        class="h-55px h-lg-80px"
    />
    </span>
    
</a>
