<div [@routerTransition] class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
        <div
            id="kt_aside"
            class="aside py-9"
            data-kt-drawer="true"
            data-kt-drawer-name="aside"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_aside_toggle"
        >
            <div class="aside-logo flex-column-auto px-9 mb-9" id="kt_aside_logo">
                <theme3-brand></theme3-brand>
            </div>
            <div class="aside-menu flex-column-fluid ps-5 pe-3 mb-9" id="kt_aside_menu">
                <div
                    class="w-100 hover-scroll-overlay-y d-flex pe-2"
                    id="kt_aside_menu_wrapper"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-height="auto"
                    data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                    data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu, #kt_aside_menu_wrapper"
                    data-kt-scroll-offset="100"
                >
                    <side-bar-menu [menuClass]="'menu menu-column menu-rounded menu-sub-indention menu-active-bg fw-semibold my-auto'"></side-bar-menu>
                </div>
            </div>
        </div>
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <div id="kt_header" class="header">
                <div
                    [class]="containerClass + ' d-flex align-items-center justify-content-between'"
                    id="kt_header_container"
                >
                    <div
                        class="
                            page-title
                            d-flex
                            flex-column
                            align-items-start
                            justify-content-center
                            flex-wrap
                            me-lg-2
                            pb-5 pb-lg-0
                        "
                        data-kt-swapper="true"
                        data-kt-swapper-mode="prepend"
                        data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                    ></div>
                    <div class="d-flex d-lg-none align-items-center ms-n2 me-2">
                        <div class="btn btn-icon btn-active-icon-primary" id="kt_aside_toggle">
                            <span class="svg-icon svg-icon-1 mt-1">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor"></path>
                                    <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor"></path>
                                </svg>
                            </span>
                        </div>
                        <theme3-brand [logoSize]="'sm'"></theme3-brand>
                    </div>
                    <div class="d-flex align-items-center flex-shrink-0">
                        <active-delegated-users-combo [customStyle]="'me-2'"></active-delegated-users-combo>
                        <subscription-notification-bar
                            [customStyle]="
                                'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px position-relative'
                            "
                        ></subscription-notification-bar>
                        <quick-theme-selection
                            [customStyle]="
                                'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px position-relative'
                            "
                        ></quick-theme-selection>
                        <language-switch-dropdown *ngIf="!appSession.tenantId"
                            [customStyle]="
                                'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px position-relative'
                            "
                            [isDropup]="true"
                        ></language-switch-dropdown>
                        <header-notifications
                            [customStyle]="
                                'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px position-relative'
                            "
                        ></header-notifications>
                        <chat-toggle-button
                            [customStyle]="
                                'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px position-relative'
                            "
                        ></chat-toggle-button>

                        <toggle-dark-mode *ngIf="'App.Themes'|checkFeature" 
                            [customStyle]="
                                'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px position-relative'
                            "
                            [isDarkModeActive]="isDarkModeActive()"
                        ></toggle-dark-mode>

                        <user-menu
                            [iconOnly]="true"
                            [togglerCssClass]="
                                'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px position-relative'
                            "
                        ></user-menu>
                    </div>
                </div>
            </div>
            <router-outlet></router-outlet>
            <footer-bar></footer-bar>
        </div>
    </div>
</div>
