<div class="header-logo me-5 me-md-10 ">
    <a href="/">
        <img
            alt="Logo"
            *ngIf="!appSession.tenant || !appSession.tenant.HasLogo()"
            src="/assets/common/images/app-logo-on-dark-sm.svg"
            class="logo-default h-45px"
            height="38"
        />
        <img
            alt="Logo"
            *ngIf="!appSession.tenant || !appSession.tenant.HasLogo()"
            src="/assets/common/images/app-logo-on-{{skin}}-sm.svg"
            class="logo-sticky h-45px"
            height="38"
        />
        <img
            alt="Logo"
            *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
            [src]="
                remoteServiceBaseUrl +
                '/TenantCustomization/GetTenantLogo?skin=' +
                skin +
                '&tenantId=' +
                appSession.tenant.id + '&logoId='+appSession.tenant.LogoId(this.skin)
            "
            class="logo-default h-45px"
        />
        <img
            alt="Logo"
            *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
            [src]="
                remoteServiceBaseUrl +
                '/TenantCustomization/GetTenantLogo?skin=' +
                skin +
                '&tenantId=' +
                appSession.tenant.id+ '&logoId='+appSession.tenant.LogoId(this.skin)
            "
            class="logo-sticky h-45px"
        />
    </a>
</div>
