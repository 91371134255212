<div [@routerTransition] class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
        <div
            id="kt_aside"
            class="aside py-9"
            data-kt-drawer="true"
            data-kt-drawer-name="aside"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_aside_toggle" [ngClass]="{'sidbarHeight': subscriptionStatusBarVisible()}"
        >
            <div class="aside-menu flex-column-fluid ps-5 pe-3 mb-7" id="kt_aside_menu">
                <div
                    class="w-100 hover-scroll-overlay-y d-flex pe-2"
                    id="kt_aside_menu_wrapper"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-height="auto"
                    data-kt-scroll-dependencies="#kt_aside_footer, #kt_header"
                    data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu, #kt_aside_menu_wrapper"
                    data-kt-scroll-offset="102"
                >
                    <side-bar-menu
                        [menuClass]="
                            'menu menu-column menu-rounded menu-sub-indention menu-active-bg fw-semibold my-auto'
                        "
                        [ngClass]="{'sidbarMenuHeight': subscriptionStatusBarVisible()}"></side-bar-menu>
                </div>
            </div>
        </div>
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper" [ngClass]="{'headerHeight': subscriptionStatusBarVisible()}">
            <div class="d-block fs-6 row fixed-top text-center" [ngClass]="{ 'd-none': !subscriptionStatusBarVisible() }" style="background-color: #ffe599;font-weight: bolder;">
                <div class="pt-2 pb-2">
                    Your free trial ends in <countdown *ngIf="getTenantExpiresCountDown() >=86400" #cd
                        [config]="{ leftTime: getTenantExpiresCountDown(), format: 'd' }" />
                    <span *ngIf="getTenantExpiresCountDown() < 86400">0</span> days. Want to upgrade? <button type="button" style="color: black; background-color: #f1c231;" id="step15" (click)="navigateToPaymentScreen()" class="me-2 ms-2 btn btn-sm btn-warning"> {{ 'SeePlans' | localize }}</button> <button type="button" style="color: black; background-color: white;" (click)="redirectToQuotePage()" class="btn btn-sm btn-light"> {{ 'ContactSales' | localize }}</button>
                </div>
            </div>
            <div id="kt_header" class="header header-bg" [ngClass]="{'headerBodyHeight': subscriptionStatusBarVisible()}">
                <div class="container-fluid align-items-center">
                    <div class="header-brand me-5">
                        <div class="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show aside menu">
                            <div
                                class="btn btn-icon btn-color-white btn-active-color-primary w-30px h-30px"
                                id="kt_aside_toggle"
                            >
                                <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                                <span class="svg-icon svg-icon-1">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                            fill="currentColor"
                                        ></path>
                                        <path
                                            opacity="0.3"
                                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                            fill="currentColor"
                                        ></path>
                                    </svg>
                                </span>
                                <!--end::Svg Icon-->
                            </div>
                        </div>
                        <theme10-brand></theme10-brand>
                    </div>
                    <div class="topbar d-flex align-items-stretch">
                        <active-delegated-users-combo
                            [customStyle]="'btn btn-lg btn-borderless mb-1'"
                        ></active-delegated-users-combo>
                        <subscription-notification-bar
                            [customStyle]="
                                'btn btn-icon btn-borderless  bg-white bg-opacity-10 position-relative'
                            "
                        ></subscription-notification-bar>
                        <quick-theme-selection
                            [customStyle]="
                                'btn btn-icon btn-borderless  bg-white bg-opacity-10 position-relative'
                            "
                            [iconStyle]="'flaticon-interface-7 fs-4 text-white'"
                        ></quick-theme-selection>
                        <language-switch-dropdown *ngIf="!appSession.tenantId"
                            [customStyle]="
                                'btn btn-icon btn-borderless  bg-white bg-opacity-10 position-relative'
                            "
                            [isDropup]="true"
                        ></language-switch-dropdown>
                        <header-notifications
                            [customStyle]="
                                'btn btn-icon btn-borderless  bg-white bg-opacity-10 position-relative'
                            "
                            [iconStyle]="'flaticon-alert-2 fs-4 unread-notification text-white'"
                        ></header-notifications>
                        <chat-toggle-button
                            [customStyle]="
                                'btn btn-icon btn-borderless  bg-white bg-opacity-10 position-relative'
                            "
                            [iconStyle]="'flaticon-chat-2 fs-4 text-white'"
                        ></chat-toggle-button>

                        <toggle-dark-mode *ngIf="'App.Themes'|checkFeature" 
                            [customStyle]="
                                'btn btn-icon btn-borderless  bg-white bg-opacity-10 position-relative'
                            "
                            [isDarkModeActive]="isDarkModeActive()"
                        ></toggle-dark-mode>

                        <user-menu [togglerCssClass]="'symbol symbol-40px cursor-pointer'"></user-menu>
                    </div>
                </div>
            </div>
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <router-outlet></router-outlet>
            </div>
            <footer-bar></footer-bar>
        </div>
    </div>
</div>
